import React, { Component } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { graphql /*Link */ } from 'gatsby';
import ToursGrid from '../components/ToursGrid';
import TourPlaylistBanner from '../components/TourPlaylistBanner';
import AppContext from '../context/AppContext';
import PageHeader from '../components/PageHeader';
import Accordion from '../components/Accordion';
import format from 'string-format-obj';
import DataContext from '../context/DataContext';
import { DataProvider } from '../context/DataProvider';
import { OrganizationTypeFragment } from '../fragments/organizationTypeFragment';
import { CapabilityFragment } from '../fragments/capabilityFragment';
import { PriorityFragment } from '../fragments/priorityFragment';
import { TourFragment } from '../fragments/tourFragment';
import { TourSeoFragment } from '../fragments/tourSeoFragment';
import { PrismicMetaFragment } from '../fragments/prismicMetaFragment';
import { OrganizationTypesFragment } from '../fragments/organizationTypesFragment';
import { PrioritiesFragment } from '../fragments/prioritiesFragment';
import { CapabilitiesFragment } from '../fragments/capabilitiesFragment';
import TourTabs from '../components/TourTabs';
import { RichText } from 'prismic-reactjs';
class CustomPage extends Component {
  constructor(props) {
    super(props);

    // let moreTours = props.data.prismic.allTours.pageInfo.hasNextPage
    //   ? props.data.prismic.allTours.pageInfo.hasNextPage
    //   : false;

    // this.state = {
    //   moreTours: moreTours,
    //   tours: props.data.prismic.allTours.edges.map(x => x.node),
    //   tourEndCursor: props.data.prismic.allTours.pageInfo.hasNextPage
    //     ? props.data.prismic.allTours.pageInfo.endCursor.toString()
    //     : null
    // };
  }

  componentDidMount() {
    // we need to check if there are more items after the initial 20
    // currently we know that there could be more tours so we need to run a logic check with the data from prisimc
    // and if it has another page to get the data from after the endCurser
    // let { moreTours, tourEndCursor, tours } = this.state;
    // if (moreTours) {
    //   this.props.prismic
    //     .load({
    //       variables: { after: `"${tourEndCursor}"` },
    //       query: moreTourQuery, // (optional)
    //       fragments: [
    //         OrganizationTypeFragment,
    //         OrganizationTypesFragment,
    //         CapabilityFragment,
    //         CapabilitiesFragment,
    //         PriorityFragment,
    //         TourFragment,
    //         PrioritiesFragment,
    //         TourSeoFragment,
    //         PrismicMetaFragment
    //       ] // (optional)
    //     })
    //     .then(res => {
    //       this.setState({
    //         tours: [...tours, ...res.data.allTours.edges.map(x => x.node)],
    //         moreTours: res.data.allTours.pageInfo.hasNextPage ? res.data.allTours.pageInfo.hasNextPage : false
    //       });
    //     });
    // } else {
    //   return null;
    // }
  }

  render() {
    const homepage = this.props.data.prismic.allHomepages.edges[0].node;
    let globalNavCTA = this.props.data.prismic.allGlobal_navigations.edges[0].node.global_cta;
    let priorities = this.props.data.prismic.allPriorities.edges.map(x => x.node);
    let capabilities = this.props.data.prismic.allCapabilities.edges.map(x => x.node);
    let organizationTypes = this.props.data.prismic.allOrganizationTypes.edges.map(x => x.node);
    let languages = this.props.data.prismic.allLanguages.edges.map(x => x.node);

    let gov_tours_custom_page = this.props.data.prismic.gov_tours_custom_page;

    const backToToursLink =
      this.props.pageContext.lang === 'en-us'
        ? `/${this.props.pageContext.uid}`
        : `/${this.props.pageContext.lang}/${this.props.pageContext.uid}`;

    let toursArray =
      gov_tours_custom_page !== null
        ? gov_tours_custom_page.tours_to_display.map((tour, index) => {
            return tour.custom_page_tour;
          })
        : [];

    let pencilBanner =
      this.props.data.prismic.allGlobal_navigations.edges[0].node.pencil_banner &&
      this.props.data.prismic.allGlobal_navigations.edges[0].node.pencil_banner.length > 0;

    return (
      <AppContext.Consumer>
        {app => {
          return (
            <DataProvider
              lang={this.props.pageContext.lang}
              languages={languages}
              tours={toursArray}
              activeFilters={app.activeFilters}
              playlist={app.playlist}
              priorities={priorities}
              capabilities={capabilities}
              organizationTypes={organizationTypes}
              setSortMethod={app.setSortMethod}
              sortMethod={app.sortMethod}
            >
              <DataContext.Consumer>
                {data => {
                  return (
                    <Layout location={'home'} lang={this.props.pageContext.lang} languages={data.languages}>
                      <SEO
                        title={homepage.meta_title}
                        description={homepage.meta_description}
                        lang={homepage.lang}
                        keywords={[]}
                      />
                      <PageHeader
                        title={gov_tours_custom_page === null ? homepage.title : gov_tours_custom_page.title}
                        content={
                          gov_tours_custom_page === null ? homepage.introduction : gov_tours_custom_page.description
                        }
                        capabilities={capabilities}
                        globalNavCTA={globalNavCTA}
                        customPage={true}
                        pencilBanner={pencilBanner}
                      />
                      <div className="container-fluid main-container">
                        <div
                          className={`filter-overlay ${app.showFilters || app.showSort ? '' : 'hide'} `}
                          onClick={() => app.closeOverlay()}
                        />
                        <div className="container">
                          <div className="row"></div>
                          {/* mobile sort & filter */}
                          <div className="row">
                            <div className="filter-accordion-mobile-wrapper">
                              <div className="filter-accordion-mobile"></div>
                              <div className="filter-accordion-desktop">
                                {app.showFilters && (
                                  <div className="filter-container-buttons">
                                    <div className="accordion-container">
                                      <button onClick={() => app.clearFilters()} className="filter-reset-button">
                                        {homepage.reset_all_filters}
                                      </button>
                                      <Accordion />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* desktop sort & filter */}
                          <div className="row">
                            {app.showSort && (
                              <div className="sort-container-desktop">
                                <div className="sorted-tours-container">
                                  <div className="sorted-tours-wrapper">
                                    {app.sortOptions.map((option, index) => {
                                      return (
                                        <li className="sort-item" key={index} onClick={() => app.setSortMethod(option)}>
                                          <span className="sort-check-flex">
                                            <span>{homepage[option]}</span>
                                            <span className={`sort-check ${option === app.sortMethod ? '' : 'hidden'}`}>
                                              &#x2713;
                                            </span>
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* <br /> */}
                          <div className="row">
                            <div className={`tour-container ${!app.showSort ? 'no-tour-tabs' : ''}`}>
                              <ToursGrid
                                tours={app.activeFilters.length ? data.filteredTours : toursArray}
                                sortMethod={app.sortMethod}
                                addToPlaylist={app.addToPlaylist}
                                removeFromPlaylist={app.removeFromPlaylist}
                                playlist={app.playlist}
                                labels={{
                                  new: homepage.new,
                                  capabilities: homepage.capabilities,
                                  add_to_playlist: homepage.add_to_playlist,
                                  added_to_playlist: homepage.added_to_playlist
                                }}
                                customPage={true}
                                backToToursLink={backToToursLink}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`playlist-feature-container ${app.showAddToPlaylistButtons ? 'hide' : ''}`}>
                          <div className="playlist-button-wrapper">
                            Want to create your own playlist from selected tours?
                            <button className="show-playlist-button" onClick={() => app.activatePlaylistFeature()}>
                              Create A Playlist
                            </button>
                          </div>
                        </div>
                        {app.showAddToPlaylistButtons && (
                          <div className="tour-playlist-wrapper">
                            <TourPlaylistBanner
                              playlist={app.playlist}
                              setCurrentTourTitle={app.setCurrentTourTitle}
                              labels={homepage}
                              customPage={true}
                              backToToursLink={backToToursLink}
                            />
                          </div>
                        )}
                      </div>
                    </Layout>
                  );
                }}
              </DataContext.Consumer>
            </DataProvider>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

const moreTourQuery = `
  query MoreTourQuery($lang: String!, $after: String) {
    prismic {
      allTours(lang: $lang, after: $after) {
        edges {
          node {
            ...TourFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const query = graphql`
  query CustomTourPageQuery($uid: String!, $lang: String!, $after: String) {
    prismic {
      allHomepages(lang: $lang) {
        edges {
          node {
            ...HomepageFragment
          }
        }
      }
      allLanguages(lang: $lang) {
        edges {
          node {
            ...LanguageFragment
          }
        }
      }
      allTours(lang: $lang, after: $after) {
        edges {
          node {
            ...TourFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      allPriorities: allPrioritys(lang: $lang) {
        edges {
          node {
            ...PriorityFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      allCapabilities: allCapabilitys(lang: $lang) {
        edges {
          node {
            ...CapabilityFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      allOrganizationTypes: allOrganization_types(lang: $lang) {
        edges {
          node {
            ...OrganizationTypeFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      allGlobal_navigations(lang: $lang) {
        edges {
          node {
            ...GlobalNavigationFragment
          }
        }
      }
      gov_tours_custom_page(lang: $lang, uid: $uid) {
        tours_to_display {
          custom_page_tour {
            ...TourFragment
          }
        }
        _meta {
          uid
        }
        title
        description
      }
    }
  }
`;

export default CustomPage;
